import {useEffect, useState} from "react"
import axios from "axios"
import { useCookies } from 'react-cookie';

import './KatXmas2022.css'

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
);

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

function whichImageType(dimensions) {
    var dimen = getWindowDimensions()
    if(dimen.height < 1200) {
        return 2;
    }
    return 1;
}

export default function KatXmas2022Page() {
    const [data, setData] = useState({loading:true, manifest:null})
    const [loading, setLoading] = useState(true);
    const [locked, setLocked] = useState(0)
    const [userAuthKey, setUserAuthKey] = useState("")
    const [authKey, setAuthKey] = useCookies(['xmas22-auth-key'])
    const [authError, setAuthError] = useState("")
    const [manifest, setManifest] = useState("")
    const [showMessage, setShowMessage] = useState("")
    const [refreshFunc, setRefreshFunc] = useState(null)
    const [useImageType] = useState(whichImageType());

    const doRefresh = () => {
        console.log("refreshing api!");
        doAmyFetch()
    }    

    const doAmyFetch = () => {
        console.log('FETCHING')
        setLoading(true);
        var submitPassword = "";

        console.log(authKey.authKey)

        if(userAuthKey.length > 0) {
            submitPassword = userAuthKey;
        } else if(authKey.authKey != null) {
            submitPassword = authKey.authKey2022;
        }
        console.log("submitPassword is " + submitPassword)

        if(submitPassword.length === 0) {
            setLoading(false);
            return
        }
        console.log("calling with password=" + submitPassword)
        console.log("calling with iamge_type=" + useImageType)
    
        axios
            .post('https://woe5go2foft7dkzigexnsofiqe0kgnjo.lambda-url.us-west-1.on.aws/',
                    {"key":submitPassword, 'image-type': useImageType, 'sns':1, 'year':2},
                    {headers: {'Content-Type': 'application/json'}})
            .then(postResult => {
                var x = postResult.data
                console.log(postResult)
                console.log(x)
                if(x['authorized'] === 0) {
                    setAuthError("unrecognized answer!")
                    setLoading(false);
                }
                else {
                    if(!submitPassword.includes('-')) {
                        setAuthKey('authKey2022', submitPassword);
                    }
                    var manifestUrl = x['image-root'] + x['manifest-name']
                    axios.get(manifestUrl).then(res => {
                        const shuffledImages = res.data.images.sort((a, b) => 0.5 - Math.random());
                        var loadedImages = shuffledImages.map( (isrc) => { 
                            var i = new Image();
                            i.src = x["image-x-root"] + isrc
                            return i
                        })
                        console.log(shuffledImages)

                        if(x['authorized'] === 1) {
                            setShowMessage(x['message'])
                        }

                        if(x['refresh-in'] > 0) {
                            var refreshMs = x['refresh-in'] * 1000

                            if(refreshFunc != null) {
                                clearTimeout(refreshFunc)
                                setRefreshFunc(null)
                            }

                            setRefreshFunc(setTimeout(doRefresh, refreshMs));
                            console.log("refreshing in " + refreshMs)
                        }
            
                        setManifest({images: shuffledImages, loaded: loadedImages})
                        setData(x)
                        setLocked(x['authorized']);
                        setLoading(false);
                    })
                }
            }
        );
    }


    useEffect(() => {
        doAmyFetch()
    }, [])

    const onAuthChange = (event) => {
        setUserAuthKey(event.target.value);
    }

    const onAuthSubmit = (event) => {
        event.preventDefault();
        doAmyFetch();
    }

    if(loading) {        
        return (
            <>
            ... loading ...
            </>
        );    
    }

    if(locked === 0) {
        return (
            <>
            What is the name of our wine-infused throuple boyfriend?
            <form id="auth" onSubmit={onAuthSubmit}>
                <input name="password" value={userAuthKey} onChange={onAuthChange}></input>
            </form> 
            <button onClick={onAuthSubmit}>Submit</button>
            <div>{authError}</div>
            </>
        )
    }
    else if(locked === 1) {
        return <div class="xmas-container" style={{"background-image": "url(" + data['image-background'] + ")"}}>
            <div class="ac-title">{showMessage}</div>
        </div>
    } 
    else if(locked === 2) {
        return (            
            <div class="xmas-container" style={{"background-image": "url(" + data['image-background'] + ")"}}>
                <div class="ac-title">Direct link: <a href={data['video-direct']}>download</a></div>
                <div class="video-container">
                    <YoutubeEmbed embedId={data['video-embed']} />
                </div>
                <div>download link</div> 
            </div>
        )
    }
    else {
        return (
            <>
            locked was {locked}
            </>
        )
    }
}
