export default function HomePage() {
    return (
        <>
        <header className="App-header">            
          <p>
            *.
          </p>
        </header>
        </>
    );
}