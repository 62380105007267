import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import HomePage from './pages/HomePage'
import KatXmas2021Page from './pages/KatXmas2021Page'
import KatXmas2022Page from './pages/KatXmas2022Page'
import GalinaBirthday23 from './pages/GalinaBday2023'

function App() {
  return (    
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ <HomePage/> } />
          <Route exact path="/kat-xmas-2021" element={ <KatXmas2021Page/> } />
          <Route exact path="/kat-xmas-2022" element={ <KatXmas2022Page/> } />
          <Route exact path="/galina-bday-2023" element={ <GalinaBirthday23/> } />
        </Routes>
    </BrowserRouter>      
    </div>
  );
}

export default App;
