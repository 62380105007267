import { Link } from "react-router-dom";

const therapists = ["https://www.manhattanalternative.com/team/lauren-clapp/",
"https://www.manhattanalternative.com/team/sanaria-hussain/",
"https://www.manhattanalternative.com/team/kate-klein/",
"https://www.manhattanalternative.com/team/caitlin-morrison/",
"https://www.manhattanalternative.com/team/jess-laliberte/",
"https://www.manhattanalternative.com/team/erica-oberhand/",
"https://www.manhattanalternative.com/team/alissa-silverstein/",
"https://www.dulcineapitagora.com/rebecca-emma-kaplan"]

function TherapistsList() {
    return "hi"
}

export default function GalinaBirthday23() {
    return (
        <>
        <header className="App-header">            
          <p>
            <div class="theader">
                Database: <a href="https://www.manhattanalternative.com/">https://www.manhattanalternative.com/</a>
            </div>
            <p></p>
            <div class="theader">
                Random ones that looked interesting:
            </div>
            {therapists.map(l => (
                <div class="tlink"><a href={l}>{l}</a></div>
            ))}
          </p>
        </header>
        </>
    );
}